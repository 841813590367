import React from "react";
import Helmet from "react-helmet";
import TwoThirdsLayout from "../layouts/TwoThirdsLayout";
import ProjekteListe from "../components/ProjektListe";
import Title from "../components/Title";

import Wrapper from "../layouts/Wrapper";

import { colors } from "../styles/__variables";
import Paragraph from "../components/Paragraph";

const styles = {
  backgroundLeft: colors.white,
  backgroundRight: "url(/assets/images/background/photocase_1626174.jpg)",
  navColor: colors.grey.base,
  navColorDesktop: colors.white,
};

// no innerflex, align innerflex items to center!

const Warum: React.FC = () => {
  return (
    <TwoThirdsLayout styles={styles}>
      <Helmet
        title="Projekte – Rundum HR"
        link={[
          {
            rel: "shortcut icon",
            type: "image/png",
            href: `./assets/favicon/android-chrome-192x192.png`,
          },
        ]}
      />

      <div>
        <Wrapper>
          <Title color={colors.green.base}>Projekte</Title>
          <Paragraph spaced color={colors.green.text}>
            Diese Projekte habe ich bereits geleitet und umgesetzt:
          </Paragraph>
          <ProjekteListe />
        </Wrapper>
      </div>
      <div>
        <div />
      </div>
    </TwoThirdsLayout>
  );
};

export default Warum;
