import React from "react";
import styled from "styled-components";
import { spacers, colors } from "../styles/__variables";
import Paragraph from "./Paragraph";

interface ProjekteListenItemProps {
  item: string;
  index: number;
}

const ListenItem = styled.li`
  padding: ${`${spacers.mobile}px`};
  background: ${colors.grey.background};
  margin-bottom: ${`${spacers.mobile}px`};
`;

const ProjekteListenItem: React.FC<ProjekteListenItemProps> = ({
  item,
  index,
}) => {
  return (
    <ListenItem key={index}>
      <Paragraph color={colors.grey.text}>{item}</Paragraph>
    </ListenItem>
  );
};

export default ProjekteListenItem;
