import React from "react";
import styled from "styled-components";
import ProjekteListenItem from "./ProjekteListenItem";
import { projekte } from "./data";

const Liste = styled.ul`
  margin: 0;
  list-style-type: none;
`;

const ProjekteListe: React.FC = () => {
  return (
    <Liste>
      {projekte.map((projekt) => (
        <ProjekteListenItem index={projekt.index} item={projekt.item} />
      ))}
    </Liste>
  );
};

export default ProjekteListe;
